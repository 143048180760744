export default function pacValidationWithError(pac) {
  if (pac && pac.length === 0) {
    return 'Please enter an PAC';
  }
  if (pac && pac.length !== 4) {
    return 'PAC should be atleas 4 digits long';
  }
  const reg = /^\d+$/;
  const success = reg.test(pac);
  if (!success) {
    return 'Please enter a valid PAC';
  }
  return null;
}
