import React, { useState, useEffect } from 'react';
import pacValidationWithError from '../../helpers/pacValidationWithError';
import Loader from '../Loader';
import useAPI from '../../NetworkCall/API';
import { setRollbarWarning } from '../../helpers/rollBar';

const HypurVerificationPopup = ({ data, dismiss, checkout, enterPac }) => {
  const [pacError, setPacError] = useState(false);
  const [pac, setPac] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const { enterPACNumber: callEnterPACNumber } = useAPI();

  async function enterPACNumber(pacNumber) {
    // Pay by hypur
    try {
      const paymentObject = { order_id: data.order_id, pac_number: pacNumber };
      setShowLoader(true);
      await callEnterPACNumber(paymentObject);
    } catch (error) {
      setRollbarWarning('HypurVerification.js_enterPANnumber_catch', error);
      console.error('HypurVerification.js_enterPANnumber_catch', error);
    } finally {
      setShowLoader(false);
      dismiss();
    }
  }

  function handleApply() {
    const newPacError = pacValidationWithError(pac);
    if (!newPacError) {
      if (enterPac) {
        enterPACNumber(pac);
      } else {
        checkout(pac);
      }
    } else {
      setPacError(newPacError);
    }
  }

  function setLocalPac(e) {
    setPac(e.target.value);
    setPacError(false);
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
    return () => {
      body.classList.remove('modal-open');
    };
  }, []);

  return (
    <div className="modal confirm d-block location-popup" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h6 className="modal-title text-center bold">Hypur Verification</h6>
          </div>
          <div className="modal-body text-center">
            <p>Please enter PAC number below that You entered at the time of Hypur sign-up.</p>
            <div className="input-parent relative one-rem-mb">
              <span className="l-icon icon-otp2" />
              <input
                type="tel"
                placeholder="Enter your PAC"
                className="form-control"
                value={pac}
                onChange={setLocalPac}
                maxLength={4}
              />
              {pacError && <div className="text-left error half-rem-mt">{pacError}</div>}
            </div>
          </div>
          {showLoader ? (
            <Loader />
          ) : (
            <div className="modal-footer justify-content-center px-2">
              <button type="button" className="btn btn-primary bold rouded-button has-box-shadow" onClick={handleApply}>
                Submit
              </button>
              <button type="button" className="btn btn-danger bold rouded-button has-box-shadow" onClick={dismiss}>
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HypurVerificationPopup;
