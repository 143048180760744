import React, { useState, useContext, useEffect, Fragment } from 'react';
import Router, { useRouter } from 'next/router';
import Collapse, { Panel } from 'rc-collapse';

import toFixedFloat from '../helpers/toFixedFloat';
import StorageService from '../services/storageService';
import appContext from '../Context/appContext';
import { useLoyaltyDiscount } from '../hooks/User';
import useAPI from '../NetworkCall/API';
import notificationTypes from '../constants/modalNotificationConst';
import * as WEBSITES from '../constants/website';
import { setRollbarWarning } from '../helpers/rollBar';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../redux/slices/modalSlice';

const FriendsFamilyCollapse = ({ className, forWeb }) => {
  const {
    state: {
      // cart: { cartData: { calculatedAmount, expirable_cashback } = {} } = {},
      // user: { isLoggedIn },
      cashBacks
    }
  } = useContext(appContext);

  const { expirable_cashback } = useSelector(state => state.cart?.cartData) || {}
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const dispatchRedux = useDispatch()
  const [isVisible, setIsVisible] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { isLoyal, isLoading, data: discountPercent } = useLoyaltyDiscount();

  const isFriends = StorageService.getFnfLocal();
  const router = useRouter();

  const { FnFOptIn, getCart } = useAPI();

  const AccordionIconReverse = ({ isActive }) => (
    <i className={`fnf-arrow-btn ${!isActive ? 'icon-down d-none' : 'icon-down'}`} />
  );
  async function exitPopup() {
    if (isLoggedIn) {
      // if (fnfFlag) {
      try {
        if (isLoyal && discountPercent > 10) {
          getCart();
          dispatchRedux(pushNotification({ type: notificationTypes.friendsAndFamilyDiscount }))
        } else {
          setButtonDisabled(true);
          await FnFOptIn();
          getCart();
          setButtonDisabled(false);
          dispatchRedux(pushNotification({ type: notificationTypes.friendsLogin }))
        }
      } catch (err) {
        setButtonDisabled(false);
        setRollbarWarning('FriendsFamilyCollapse.jsx_exitPopup_catch', err);
        console.error('FriendsFamilyCollapse.jsx_exitPopup_catch', err);
      }
      // } else {
      //   setError(true);
      // }
    } else {
      router.push('/login?friends=true');
      dismiss();
    }
  }
  return (
    <>
      {process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && !isFriends && isLoggedIn && isVisible && (
        <div className={`  friends-family-pop-up rounded-card  mt-3`}>
          <div className={` friends-family-cart-rewards rounded-card relative ${className}`}>
            <img src="static/images/fnf_mob.png" className="img-fluid" />
            {/* <Collapse className="cart-colllapse" expandIcon={AccordionIconReverse}>
              <Panel
                header={(
                  <div className="d-flex justify-content-space-between align-items-center w-100 fnf-header pr-4 pr-lg-0">
                    Increase your credit back rewards by 50% and get a $$
                  {cashBacks ? cashBacks.prime_sign_up_bonus : 0} credit by joining Grassdoor Friends & Family!
                    <div className="flex-1">
                      <div className="bold">Join Grassdoor Friends & Family!</div>
                      <div className="small">Earn Bigger Rewards</div>
                    </div>

                    <button className="btn white-btn f-f-sign-up">Join Now</button>
                  </div>
                )}
              
            
              >
                <div className="collapse-content">

                  <div className=" text-content">
                    <div className="d-flex justify-content-between">
                      <span>Your total</span>
                      <span> ${toFixedFloat(calculatedAmount)}
                      </span>
                    </div>
                    <div className="bold d-flex justify-content-between my-2">
                      <span> {expirable_cashback ? expirable_cashback.percentage : 0} % Credit Back</span>
                      <span> ${expirable_cashback ? expirable_cashback.amount : 0}
                      </span>
                    </div>
                    <hr />
                    <div className="bold d-flex justify-content-between">
                      <span>Credits towards future purchases</span>
                      <span>
                        ${expirable_cashback ? expirable_cashback.amount : 0}
                      </span>
                    </div>
                    {expirable_cashback && expirable_cashback.formatted_expiry_date !== null && (
                      <div className="bold d-flex justify-content-between">
                        <span>Expires on</span>
                        <span>{expirable_cashback.formatted_expiry_date}</span>
                      </div>
                    )}
                  </div>
                  <div className=" text-center btn-content">
                    <h5 className="pr-1 text-left">
                      Increase your credit back rewards by 50% and get a $
                      {cashBacks ? cashBacks.prime_sign_up_bonus : 0} credit
                      by joining Grassdoor Friends & Family!
                    </h5>
                    <div className=" text-center one-rem-mt">
                      <button className="btn white-btn" onClick={() => exitPopup()}>
                        Join for Free
                      </button>
                    </div>
                  </div>

                </div>
              </Panel>
              <button className="btn fnf-close-btn d-lg-none" onClick={() => setIsVisible(false)} ><i className="icon-close" /> </button>
            </Collapse> */}
            <div className="ff-block ">
              <h6 className="mb-2">Grassdoor Friends & Family</h6>
              <p className="pr-1 text-left mb-2">
                Increase your credit back rewards by <b>50%</b> and get a <b>
                  ${cashBacks ? cashBacks.prime_sign_up_bonus : 0}</b>
                &nbsp;credit by joining Grassdoor Friends & Family!
              </p>
              <p>
                You can earn $<b>{expirable_cashback ? expirable_cashback.amount : 0}</b>
                &nbsp;credit on this order.
              </p>
              <div className=" one-rem-mt">
                <button className="btn btn-outline-primary" onClick={() => exitPopup()} disabled={buttonDisabled}>
                  Join for Free
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FriendsFamilyCollapse;

const TempDiv = () => <div>Temp Div</div>;
