import axiosInstance from "../utils/ajax"

const API_URL = process.env.NEXT_PUBLIC_SECURE_EC2_URL;

export const getSessionID = async (data = {}) => {
  try {
    let config = {
      url: `${API_URL}/payments/swifter/session`,
      method: 'POST',
      data: data
    }
    let res = await axiosInstance(config);
    if (res.data.success) {
      return res.data.data
    } else {
      return {
        data: null
      }
    }

  } catch (error) {
    return {
      data: null,
      error: error
    }

  }
}