import React, { useState, useRef, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import useAPI from '../../NetworkCall/API';
import Loader from '../Loader';

const LedgerGreenVerificationPopup = ({ dismiss, amount, checkout }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [agreementCheck, setAgreementCheck] = useState(true);
  const [loading, setLoading] = useState(true);
  const [agreementDetails, setAgreementDetails] = useState();
  const { getLedgerGreenAgreementDetails } = useAPI();

  useEffect(async () => {
    const response = await getLedgerGreenAgreementDetails({ amount });
    setAgreementDetails(response?.agreementData);
    setLoading(false);
  }, []);

  const onAgreementCheck = () => {
    setAgreementCheck(!agreementCheck);
  };

  const signaturePad = useRef();

  function callSetCanvasWidth() {
    if (signaturePad.current && !canvasWidth) {
      const canvas = signaturePad.current.getCanvas();
      if (canvas.parentElement) {
        setCanvasWidth(canvas.parentElement.clientWidth);
      }
    }
  }

  function clearSignaturePad() {
    if (signaturePad.current) {
      signaturePad.current.clear();
    }
  }

  async function handleSubmit() {
    if (signaturePad.current && signaturePad.current.isEmpty()) {
      setErrorMessage('Please provide signature first.');
    } else if (!agreementCheck) {
      setErrorMessage('Authorization acceptance required!');
    } else {
      setErrorMessage(null);
      checkout(signaturePad.current.toDataURL());
      dismiss();
    }
  }
  useEffect(() => {
    callSetCanvasWidth();
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
    return () => {
      body.classList.remove('modal-open');
    };
  }, []);

  return (
    <div className="modal d-block driver-rating ledger-green-popup d-flex justify-content-center" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content relative">
          <div className="top-sticky">
            <button className="btn close-btn fs-14" onClick={dismiss}>
              <i className="icon-close" />
            </button>
            <div className="d-flex justify-content-between modal-header pb-2">
              <p className="bold mb-0">Authorize Payment</p>
            </div>
          </div>
          <div className="text-left pb-5">
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="form-group ">
                  <div className="custom-checkbox one-rem-mt ">
                    <input type="checkbox" checked={agreementCheck} id="ageCheck" name="ageCheck" onChange={onAgreementCheck} />
                    <label className="align-top m-0 text-left" htmlFor="ageCheck">
                      <span className="inner normal-font fs-13">{agreementDetails?.iAgree}</span>
                    </label>
                  </div>
                </div>
                <div className="ledger-green-agreement two-rem-mb">{agreementDetails?.response}</div>
              </>
            )}
            <div className="d-flex align-items-center justify-content-between half-rem-mb">
              <p className="bold mb-0 fs-16">Signature</p>
              <button
                type="button"
                className="clear-btn red-color text-underline white-bg border-none"
                onClick={clearSignaturePad}
              >
                Clear
              </button>
            </div>
            <div className="sign-container relative">
              <SignaturePad
                ref={signaturePad}
                canvasProps={{
                  className: 'sig-pad',
                  height: '220',
                  width: canvasWidth
                }}
                minWidth="1.5"
                maxWidth="1"
                dotSize="1"
              />
            </div>
          </div>
          <div className="bottom-sticky">
            <p className="red-color">{errorMessage}</p>
            <button type="button" className="btn btn-primary rouded-button bold has-box-shadow w-100 py-2" onClick={handleSubmit}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LedgerGreenVerificationPopup;
